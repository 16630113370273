var slug = require('slug');
var Moment = require('moment');
var localization = require('moment/locale/it');

slug.defaults.modes['pretty'] = {
    replacement: '-',
    symbols: true,
    remove: /[.]/g,
    lower: true,
    charmap: slug.charmap,
    multicharmap: slug.multicharmap
};
slug.defaults.mode ='pretty';

module.exports = {
    slugify: (text) => {
      if (!!text)
        return slug(text);
    },
    flatten: (object) => {
        return Object.assign( {}, ...function _flatten( objectBit, path = '' ) {  
        if (objectBit) {
            return [].concat(                                                       
            ...Object.keys( objectBit ).map(                                      
                key => typeof objectBit[ key ] === 'object' ?                       
                _flatten( objectBit[ key ], `${ path }/${ key }` ) :             
                ( { [ `${ path }/${ key }` ]: objectBit[ key ] } )                
            )
            )
        } else 
            return[]
        }( object ) )
    },
    formatDate: (data) => {
        console.log("data",data);
        const mdate = Moment(data).locale('it',localization);
        let datestring = mdate.format('LL');
        return datestring;
    }
}