import React from 'react'
import { Link } from 'gatsby'
import utils from '../utility/utils'
import Img from 'gatsby-image'

class BoxNews extends React.Component {

  render() { 
    const item = this.props.data;
    return (
      <article className="news">
        {!!item.node.immagine &&
         <Img fluid={item.node.immagine.fluid}/>
        }
        <div className="news__info">
          <h2 className="news__title">
            <Link to={"/news-ed-eventi/"+item.node.friendlyUrl} title={item.node.titolo} className="news__link">{item.node.titolo}</Link>
          </h2>
          <div className="news__category">{item.node.argomento}</div>
          <div className="news__abstract" dangerouslySetInnerHTML={{ __html: item.node.abstract.childMarkdownRemark.html }} >
          </div>
          <span className="news__date">{utils.formatDate(item.node.data)}</span>
        </div>
      </article>
    )
  }

}

export default BoxNews