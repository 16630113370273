import React from 'react'
import Helmet from 'react-helmet'
import Hero from '../components/hero'
import PlaceHolder from '../components/placeholder'
import Layout from '../components/layout'
import { graphql, Link } from 'gatsby'
import BoxAree from '../components/box-aree'
import FeaturedNews from '../components/featured-news'
import BoxNews from '../components/box-news'

const datahero = {
  "title": "Centro Zootecnico Didattico Sperimentale",
  "subtitle": "",
  "arealink": false,
  "heading": true
};

const aree = [
  {
    "title": "Area Studenti",
    "abstract": "Come Ospedale Universitario offriamo agli studenti di studiare sul campo attraverso un percorso unico",
    "img": "/images/contents/ico-student.png",
    "link": "/area-studenti/"
  },
  {
    "title": "Area Enti",
    "abstract": "Scopri i servizi dedicati agli enti convenzionati con l'Ospedale",
    "img": "/images/contents/ico-bear.png",
    "link": "/area-enti/"
  },
  {
    "title": "Area Aziende",
    "abstract": "L'Ospedale Veterinario dell'Università di Milano offre servizi specializzati e di avanguardia per la cura delle diverse specie animali",
    "img": "/images/contents/icon-building.png",
    "link": "/area-aziende/"
  },
];



class IndexPage extends React.Component {

  render() { 
    const home = this.props.data.contentfulLayoutHome;
    const reparti = home.reparti;
    const progetti = home.progetti;
    const heroimg = home.immagineHero;
    const news = this.props.data.news.edges;
    const stickeyNews = this.props.data.sitckynews.edges;
    const allNews = [...stickeyNews, ...news];
    const avvisoEvidenza = this.props.data.contentfulAvvisoEvidenza;
  
    return (
    <Layout location={this.props.location}>
       <Helmet
           bodyAttributes={
            { class :'has-hero'}
          }
          meta={[
            { property: 'og:site_name', content: 'Centro Zootecnico Didattico Sperimentale'},
            { property: 'og:type', content: 'website' },
            { property: 'og:title', content: `Centro Zootecnico Didattico Sperimentale` },
            { property: 'og:description', content:  ``},
            { property: 'og:image', content: `${process.env.NODE_ENV === 'production'?'http://www.ospedaleveterinario.unimi.it':''}${datahero.img}`}
          ]}
        />
        {avvisoEvidenza && avvisoEvidenza.visibile && <FeaturedNews data={avvisoEvidenza} />}
        
        <Hero data={datahero} cover={heroimg}/>

        <BoxAree data={aree}/>

        <div className="container">
          <div className="row">
            <div className="col-12">
            
              <section className="section"  aria-labelledby="reparti">
                <h1 id="reparti" className="section__title">Reparti</h1>
                <div className="row flex-wrap">
                  {reparti.map((item,index) => 
                    <div key={index} className="col-6">
                        <PlaceHolder data={item}/>
                    </div>
                  )}
                </div>
              </section>
        
            </div>
          </div>

          <div className="row">
            <div className="col-12">
            
              <section className="section"  aria-labelledby="progetti">
                <h1 id="progetti" className="section__title">Progetti</h1>
                <div className="row flex-wrap">
                  {progetti.map((item,index) => 
                    <div key={index} className="col-6 col-lg-4">
                        <div className="placeholder">
                          <div className="placeholder__title">
                            <Link to={"/progetti/"+item.friendlyUrl} className="placeholder__link">{item.titolo}</Link>
                          </div>
                        </div>
                    </div>
                  )}
                </div>
              </section>

            </div>
          </div>
        </div>
        <section id="news-related" className="section section--dark"  aria-labelledby="region3">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 id="region3" className="section__title">News ed Eventi<a target="_blank" href="https://divas.unimi.it/" title="Vedi tutte" className="btn-view-all">Vedi tutte</a></h1>
              </div>
            </div>
           
           {/*  <div className="row">
              {allNews.slice(0, 3).map((item,index) => 
              <div key={index} className="col-md-4">
                <BoxNews data={item}/>
              </div>
              )}
            </div> */}
          </div>
        </section> 

    </Layout>
    )
  }
}
export default IndexPage;
export const query = graphql`
query ScopriQuery{
  contentfulLayoutHome (contentful_id: {eq: "4Db1pTHULOAd0bUx7Hjwi8"}) {
    titolo
    immagineHero {
      fluid(maxWidth: 1920){
        ...GatsbyContentfulFluid_withWebp
      }
    }
    reparti {
      immagine {
        fluid(maxWidth: 570){
          ...GatsbyContentfulFluid_withWebp
        }
      }
      titolo
      friendlyUrl
    }
    progetti {
      titolo
      friendlyUrl
    }
  }
  news: allContentfulNewsEdEventi(limit: 3, 
    sort: {fields: [data], order:DESC }, 
    filter: {inEvidenza: {ne: true} }) {
    edges {
      node {
        titolo
        friendlyUrl
        argomento
        data
        abstract {
          childMarkdownRemark {
            html
          }
        }
        immagine {
          fluid(maxWidth: 350){
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
  sitckynews: allContentfulNewsEdEventi(limit: 3, 
    sort: {fields: [data], order:DESC }, 
    filter: {inEvidenza: {eq: true} }) {
    edges {
      node {
        titolo
        friendlyUrl
        argomento
        data
        abstract {
          childMarkdownRemark {
            html
          }
        }
        immagine {
          fluid(maxWidth: 350){
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
  contentfulAvvisoEvidenza{
    titolo
    testo
    link
    visibile
  }
}
`
